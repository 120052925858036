<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<v-icon color="blue darken-4">mdi-package-variant-closed</v-icon>
					<!--end::Svg Icon-->
				</span>
				Products
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)" v-if="!pageLoading">
			<template v-if="productList.length">
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th width="50" class="p-2 blue lighten-4">#</th>
							<th width="100" class="p-2 blue lighten-4">Product #</th>
							<th width="250" class="p-2 blue lighten-4">Product Detail</th>
							<th width="250" class="p-2 blue lighten-4">Extended Information</th>
							<!-- <th width="250" class="p-2 blue lighten-4">Pricing Detail</th> -->
							<th width="250" class="p-2 blue lighten-4">Created At</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, index) in productList" :key="index">
							<td class="p-2 border-top-light-grey">
								{{ index + 1 }}
							</td>
							<td class="p-2 border-top-light-grey">
								<v-chip label outlined small color="cyan">
									{{ row.product_barcode }}
								</v-chip>
								<div>
									<v-chip class="mt-1" label outlined small :color="getStatusColor(row.product_barcode)">
										{{ row.product_status }}
									</v-chip>
								</div>
							</td>
							<td class="p-2 border-top-light-grey">
								<div class="flex-wrap">
									<div class="d-flex">
										<span class="fw-500 mr-1">Name:</span>
										<span class="fw-400 text-truncate d-block">
											<ShowValue truncate :object="row" object-key="product_name" label="name"> </ShowValue>
										</span>
									</div>
									<div>
										<span class="fw-500 mr-1">Serial No:</span>
										<span class="fw-400">
											<ShowValue
												click-to-copy
												tooltip
												tooltip-text="click to copy"
												:object="row"
												object-key="product_serial_number"
												label="serial no"
											>
											</ShowValue>
										</span>
									</div>
									<div>
										<span class="fw-500 mr-1">category:</span>
										<span class="fw-400">
											<ShowValue :object="row" object-key="product_category_name" label="category">
											</ShowValue>
										</span>
									</div>
								</div>
							</td>
							<td class="p-2 border-top-light-grey">
								<div class="flex-wrap">
									<div class="d-flex">
										<span class="fw-500 mr-1">Initial Stock:</span>
										<span class="fw-600">
											<ShowValue :object="row" object-key="initial_stock" label="initial stock"></ShowValue>
										</span>
									</div>
									<div>
										<span class="fw-500 mr-1">Reorder Level:</span>
										<span class="fw-600">
											<ShowValue :object="row" object-key="reorder_quantity" label="reorder level"></ShowValue>
										</span>
									</div>
									<div>
										<span class="fw-500 mr-1">Manufacturer:</span>
										<span class="fw-600">
											<ShowValue
												:object="row"
												object-key="product_manufacturer"
												label="manufacture"
											></ShowValue>
										</span>
									</div>
								</div>
							</td>
							<!-- 
							<td class="p-2 border-top-light-grey">
								<div class="flex-wrap">
									<div>
										<span class="fw-500 mr-1">Selling Price:</span>
										<span class="fw-600">
											<ShowPrice :object="row" object-key="purchasing_price" label="selling price"></ShowPrice>
										</span>
									</div>
									<div>
										<span class="fw-500 mr-1">Discounted Price:</span>
										<span class="fw-600">
											<ShowPrice
												:object="row"
												object-key="discount_price_formatted"
												label="discounted Price"
											></ShowPrice>
										</span>
									</div>
								</div>
							</td> -->
							<td>
								<div>
									<Chip
										tooltip
										tooltip-text="Created By"
										small
										:text="row.created_by"
										color="blue darken-4 white--text"
										class="mb-1"
									></Chip>
								</div>
								<TableDateTime
									small
									v-if="row.added_at"
									:human-format="row.added_at"
									:system-format="row.created_at"
								></TableDateTime>
							</td>
						</tr>
					</tbody>
				</table>
			</template>
			<p v-else class="m-0 row-not-found text-center">
				<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
				Uhh... There are no product at the moment.
			</p>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
	</div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
// import ShowPrice from "@/view/components/ShowPrice";
import TableDateTime from "@/view/components/TableDateTime";
import Chip from "@/view/components/Chip";

// import ImageTemplate from "@/view/components/Image";

export default {
	name: "SupplierListing",
	props: {
		typeText: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			productList: [],
			pageLoading: true,
		};
	},
	methods: {
		getSupplier() {
			ApiService.get(`supplier/${this.$route.params.uuid}/product-list`)
				.then(({ data }) => {
					console.log(data);
					this.productList = data.tbody;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		ShowValue,
		// ShowPrice,
		TableDateTime,
		Chip,
	},
	mounted() {
		this.getSupplier();
	},
};
</script>
